import React from 'react';
import { navbarStyles } from './NavbarStyles';  
import LanguageDropdown from '../../LanguageDropdown';
import logo from '../../images/logo.png';

const NavbarComponent = () => {
  return (
    <div style={navbarStyles.navbar}>
      <div style={navbarStyles.logo}>
        <img id='logo' src={logo} alt="Logo" style={navbarStyles.logoImage} />
      </div>
      <div style={navbarStyles.languageDropdown}>
        <LanguageDropdown />
      </div>
    </div>
  );
};

export default NavbarComponent;
