import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
import Barcode from "react-barcode";
import logo from "../../images/logo.png";
import { t } from "i18next";
import { styles } from "./CardGiftStyles";
const parentContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const CardPreview = ({ cardNumber, pin, FlipCard, resetFlip }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    if (FlipCard) {
      setIsFlipped(true);
    } else if (resetFlip) {
      setIsFlipped(false);
    }
  }, [FlipCard, resetFlip]);

  const isCardNumberValid = cardNumber && cardNumber.length === 16;

  return (
    <div style={parentContainerStyle}>
      <Box style={styles.cardPreview}>
        <motion.div
          style={{
            ...styles.cardWrapper,
            transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
          }}
          transition={{
            duration: 0.6,
            type: "spring",
            stiffness: 400,
            damping: 40,
          }}
        >
          <motion.div
            style={{ ...styles.cardFace, ...styles.cardFront }}
            initial={{ opacity: 1 }}
            animate={{ opacity: isFlipped ? 0 : 1 }}
          ></motion.div>
          <motion.div
            style={{ ...styles.cardFace, ...styles.cardBack }}
            initial={{ opacity: 0 }}
            animate={{ opacity: isFlipped ? 1 : 0 }}
          >
            <Box style={styles.cardBackContent}>
              <Box style={styles.blackBand}>McDonald’s® Gift Card</Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "7px",
                    md: "10px",
                  },
                }}
                style={styles.cardDescription}
              >
                {t("description_card")}
              </Typography>

              <Box style={styles.cardBottomSection}>
                <Box style={styles.barcodeContainer}>
                  {isCardNumberValid ? (
                    <Barcode
                      value={cardNumber}
                      format="CODE128"
                      width={1.5}
                      height={40}
                      displayValue={false}
                      style={styles.barcode}
                    />
                  ) : (
                    <Barcode
                      value="1111111111111111"
                      format="CODE128"
                      width={1.5}
                      height={40}
                      displayValue={false}
                      style={styles.barcode}
                    />
                  )}
                </Box>
                <div style={{ width: "40%" }}></div>
                <motion.div
                  style={styles.pin}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: isFlipped ? 1 : 0, y: 0 }}
                  transition={{ duration: 0.5, type: "spring", stiffness: 300 }}
                >
                  {formatPinNumber(pin) || "####"}
                </motion.div>
              </Box>
              <Box style={styles.cardBottomSection}>
                <motion.div
                  style={styles.cardNumber}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: isFlipped ? 1 : 0, y: 0 }}
                  transition={{ duration: 0.5, type: "spring", stiffness: 300 }}
                >
                  {formatCardNumber(cardNumber)}
                </motion.div>

                <img src={logo} alt="McDonald’s Logo" style={styles.logo} />
              </Box>
            </Box>
          </motion.div>
        </motion.div>
      </Box>
    </div>
  );
};

const formatCardNumber = (number) => {
  if (!number) return "**** **** **** ****";
  const cleanNumber = number.replace(/\D/g, "");
  const totalLength = 16;
  const displayedNumber = [];
  for (let i = 0; i < totalLength; i++) {
    if (i < cleanNumber.length) {
      displayedNumber.push(cleanNumber[i]);
    } else {
      displayedNumber.push("*");
    }
    if ((i + 1) % 4 === 0 && i < totalLength - 1) {
      displayedNumber.push(" ");
    }
  }

  return displayedNumber.join("");
};

const formatPinNumber = (number) => {
  if (!number) return "####";
  const cleanNumber = number.replace(/\D/g, "");
  const totalLength = 4;
  const displayedNumber = [];
  for (let i = 0; i < totalLength; i++) {
    if (i < cleanNumber.length) {
      displayedNumber.push("*");
    } else {
      displayedNumber.push("#");
    }
    if ((i + 1) % 4 === 0 && i < totalLength - 1) {
      displayedNumber.push(" ");
    }
  }

  return displayedNumber.join("");
};

export default CardPreview;
