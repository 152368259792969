import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFR from './locales/fr/translation.json';
import translationIT from './locales/it/translation.json';
import translationDE from './locales/de/translation.json';
const resources = {
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next)  
  .init({
    resources,
    lng: 'de',  
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
