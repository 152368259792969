import React from "react";
import { getBannerStyles } from "./BannerStyles";
import bannerImage from "../../images/banner.jpg";

const BannerComponent = ({
  title = "Default Title",
  subtitle = "Default Subtitle",
  imageUrl = "",
}) => {
  const styles = getBannerStyles(imageUrl);
  return (
    <div >
      <img style={styles.bannerImg}src={bannerImage} alt="" />
    </div>
  );
};
export default BannerComponent;
