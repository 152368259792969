import giftCard from "../../images/giftcard.jpeg";
import styled from 'styled-components';
export const Title = styled.h2`
  margin-bottom: 4%;
  font-family: "Speedee-Bold";
  font-size: 36px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 24px;
    text-align: left;
  }
`;
export const styles = {
  buttonContainer: {
    marginTop: "1em",
    },
  submitBtn: {
    backgroundColor: "#ffbc0d",
    fontSize: "16px",
    color: "#292929",
    height: "3pc",
    outline: "none",
    letterSpacing: "-.15px",
    lineHeight: "24px",
    float: "left",
    minWidth: "117px",
    width: "150px",
    fontFamily: "Speedee",
    textTransform: "none",
    borderRadius: "4px",
  },
  inputContainer: {
    fontFamily: "Speedee",
  },
  submitBtnHover: {
    backgroundColor: "#ffbc0d",
    color: "#292929",
  },
  formContainer: {
    fontFamily: "Speedee",
    padding: "0 4px",
    maxWidth: "1170px",
    margin: "0 auto",
    padding: "1em",
  },
  input: {
    fontFamily: "Speedee !important",
    "& .MuiFormLabel-root": {
      fontFamily: "Speedee",
    },
    "& .MuiInputBase-root::after": {
      borderBottom: " #2C2C27",
      "&.Mui-error::after": {
        fontFamily: "Speedee",
        borderBottom: "#d32f2f",
      },
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Speedee",
      color: "#2C2C27",
      "&.Mui-focused": {
        color: "#2C2C27",
      },
      "&.Mui-error": {
        fontFamily: "Speedee",
        color: "#d32f2f",
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Speedee",
      color: "#2C2C27",
      "&:focus": {
        outline: "none",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Speedee",
      color: "#2C2C27",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2C2C27",
      },
      "&:hover fieldset": {
        borderColor: "#2C2C27",
      },
      "&:after fieldset": {
        borderColor: "2px solid red",
      },
      "&.Mui-error fieldset": {
        borderColor: "red",
        borderBottom: "2px solid red",
      },
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Speedee",
    },
  },
  amount: {
    fontFamily: "Speedee-Light",
  },
  
  cardPreview: {
    position: "relative",
    fontFamily: "Speedee",
    width: "100%",
    maxWidth: "330px",
    height: "auto",
    aspectRatio: "330 / 210",  
    perspective: "1000px",
    margin: "0 auto",
  },
  logo: {
    width: "5%",
  },
  cardWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    transformStyle: "preserve-3d",
    transition: "transform 0.6s",
  },
  cardFace: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Speedee",
    color: "#fff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  },
  cardFront: {
    backgroundImage: `url(${giftCard})`,
    backgroundColor: "#1f1f1f",
  },
  cardBack: {
    backgroundImage: `url(${giftCard})`,
    backgroundColor: "#1f1f1f",
    color: "#fff",
    transform: "rotateY(180deg)",
    height: "100%",
  },
  cardBackContent: {
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    padding: "13px",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  cardDescription: {
     marginTop: "9%",
    color: "#fff",
  },
  barcodeContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },
  barcode: {
    backgroundColor: "transparent",
  },
  cardBottomSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    marginTop:'-4%'
  },
  cardNumber: {
    fontSize: "0.8em",
    color: "#fff",
    letterSpacing: "2px",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "left",
    flex: "1 1 60%",
  },
  pin: {
    fontSize: "1em",
    color: "transparent",
    padding: "4px",
    borderRadius: "8px",
    background:
      "repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, rgba(255, 255, 255, 0) 75%)", // Zebra stripe pattern
    backgroundSize: "20px 20px",
    textAlign: "center",
    flex: "0 1 35%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    border: "2px solid #fff",
  },
  cardPreviewTitle: {
    fontSize: "1.5em",
    color: "#fff",
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  blackBand: {
    backgroundColor: "#000",
    width: "100%",
    height: "30px",
    position: "absolute",
    top: "5px",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "1em",
    fontWeight: "bold",
  },
};
