import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { MdKeyboardArrowDown } from "react-icons/md";
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="btn_lg"
        aria-controls={open ? "language-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Flag
          code={i18n.language === "fr" ? "FR" : i18n.language.toUpperCase()}
          style={{
            width: 30,
            height: 30,
            overflow: "hidden",
            borderRadius: "50%",
            border: "0.5px solid black",
            padding: "3px",
          }}
        />
        <MdKeyboardArrowDown />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleClose("fr")}>
          <Flag code="FR" style={{ width: 24, height: 16, marginRight: 8 }} />
        </MenuItem>
        <MenuItem onClick={() => handleClose("it")}>
          <Flag code="IT" style={{ width: 24, height: 16, marginRight: 8 }} />
        </MenuItem>
        <MenuItem onClick={() => handleClose("de")}>
          <Flag code="DE" style={{ width: 24, height: 16, marginRight: 8 }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
