export const appStyles = {
 
    universal: {
       padding: '0 4px',
    },
    title: {
      fontSize: '36px',
      marginBottom: '4%',
     },
    description: {
      fontFamily: "Speedee-Bold",
      fontSize: '14px',
    },
    appContainer: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '1em',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
    },
    resultContainer: {
      marginTop: '1em',
    },
    p: {
      margin: '0',
    },
  };
  