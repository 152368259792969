import React from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styles } from "./CardGiftStyles";

const GiftCardForm = ({
  cardNumberValue,
  setCardNumberValue,
  pinCodeValue,
  setPinCodeValue,
  cardNumberError,
  setCardNumberError,
  pinCodeError,
  setPinCodeError,
  showcardNumberError,
  showpinCodeError,
  handleCardNumberChange,
  handlePinCodeChange,
  handleCardNumberBlur,
  handlePinCodeBlur,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box style={styles.inputContainer}>
        <TextField
          sx={styles.input}
          style={{
            ...styles.input,
            ...(cardNumberError ? styles.inputInvalid : {}),
          }}
          label={t("CARD_NUMBER")}
          variant="standard"
          fullWidth
          onBlur={handleCardNumberBlur}
          value={cardNumberValue}
          onChange={handleCardNumberChange}
          error={showcardNumberError}
          helperText={showcardNumberError ? t(cardNumberError) : ""}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            maxLength: 16,
          }}
          required
          margin="normal"
        />
      </Box>
      <Box style={styles.inputContainer}>
        <TextField
          sx={styles.input}
          style={{
            ...styles.input,
            ...(pinCodeError ? styles.inputInvalid : {}),
          }}
          label={t("PIN")}
          type="password"
          variant="standard"
          fullWidth
          onBlur={handlePinCodeBlur}
          value={pinCodeValue}
          onChange={handlePinCodeChange}
          error={showpinCodeError}
          helperText={showpinCodeError ? t(pinCodeError) : ""}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            maxLength: 4,
          }}
          required
          margin="normal"
        />
      </Box>
    </>
  );
};

export default GiftCardForm;
