import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { appStyles } from "./AppStyles";
import banner from "./images/banner.jpg";
import CardGiftInput from "./components/giftCard/CardGiftInput";
import BannerComponent from "./components/banner/banner";
import NavbarComponent from "./components/navbar/navbar";

const App = () => {
  const { t } = useTranslation();

  return (
    <div style={appStyles.container}>
      <Helmet>
        <meta property="og:title" content={t("siteTitle")} />
        <meta name="description" content={t("siteDescription")} />
      </Helmet>
      <NavbarComponent />
      <BannerComponent
        title={t('bannerTitle', 'Lorem Ipsum')}
        subtitle={t('bannerSubtitle', 'Lorem Ipsum')}
        imageUrl={banner}
      />
      <CardGiftInput />
    </div>
  );
};

export default App;
