export const navbarStyles = {
    navbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      borderBottom: '0.5px solid #f5f4f4',
    },
    logo: {
      width: '70%',
    },
    logoImage: {
      width: '60px',
    },
    languageDropdown: {
      display: 'flex',
    },
    languageDropdownButton: {
      backgroundColor: 'transparent',
      border: '0px',
      display: 'flex',
    },
    languageDropdownButtonImg: {
      backgroundColor: 'white',
      border: '0.5px solid black',
      padding: '4px',
    },
    languageDropdownButtonSvg: {
      marginTop: '15%',
    },
  };
  