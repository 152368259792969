import React, { useState, useCallback, useEffect } from "react";
import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardPreview from "./CardPreview";
import { styles } from "./CardGiftStyles";
import Captcha from "./Captcha";
import GiftCardForm from "./giftCardForm";
import TitleDescriptionSection from "../TitleDescriptionSection/TitleDescriptionSection";

const CardGiftInput = ({
  onSuccess,
  onError,
  title,
  description,
  showCard = true,
}) => {
  const { t, i18n } = useTranslation();
  const [cardNumberValue, setCardNumberValue] = useState("");
  const [pinCodeValue, setPinCodeValue] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [showcardNumberError, setShowcardNumberError] = useState(false);
  const [showpinCodeError, setShowpinCodeError] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState("");
  const [cardPreviewNumber, setCardPreviewNumber] = useState("");
  const [cardPreviewPin, setCardPreviewPin] = useState("");
  const [FlipCard, setFlipCard] = useState(false);
  const [resetFlip, setResetFlip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turnstileKey, setTurnstileKey] = useState(Date.now());
  const [status_code_Error, setStatus_code_Error] = useState("");
  const [ExToken, setExToken] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [amount, setAmount] = useState(null);
  const [unit, setUnit] = useState("");
  const [startTimePin, setStartTimePin] = useState(null);
  const [captchaTrigger, setCaptchaTrigger] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isclicked, setisclicked] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  useEffect(() => {
    const changeLanguage = async () => {
      try {
        const lang = document.documentElement.lang || "de";
        if (i18n.changeLanguage) {
          await i18n.changeLanguage(lang.slice(0, 2));
        } else {
          console.error("i18n.changeLanguage is not a function");
        }
      } catch (error) {
        console.error("Failed to change language:", error);
      }
    };
    changeLanguage();
  }, [i18n]);

  const handleTurnstileSuccess = useCallback(
    (token) => {
      console.log("here");
      setTurnstileToken(token);
      setIsSubmitting(true);
      onSuccess?.();
    },
    [onSuccess]
  );

  const handleTurnstileError = useCallback(() => {
    console.error("CAPTCHA error");
    setIsSubmitting(false);
    onError?.();
  }, [onError]);

  const validateCardNumber = (value) => {
    const trimmedValue = value.replace(/\D/g, "");
    if (trimmedValue.length === 0) {
      return "CARD_NUMBER_Require";
    } else if (trimmedValue.length !== 16) {
      return "CARD_NUMBER_ERROR";
    } else {
      return "";
    }
  };

  const validatePinCode = (value) => {
    const trimmedValue = value.replace(/\D/g, "");
    if (trimmedValue.length === 0) {
      return "PIN_CODE_Require";
    } else if (trimmedValue.length !== 4) {
      return "PIN_CODE_ERROR";
    } else {
      return "";
    }
  };

  const submitForm = async (token) => {
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cardNumber: cardNumberValue.replace(/\D/g, "").slice(3, -1),
          pinCode: pinCodeValue.replace(/\D/g, ""),
          turnstile_token: token,
        }),
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "An error occurred");
      }
      if (result.amount) {
        setAmount(result.amount);
        setUnit(result.unit);
      } else {
        if (result.status_code === "33") {
          setStatus_code_Error("Card_Number_Unknown");
        }
        setAmount(null);
        setUnit(null);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTurnstileToken("");
    setTurnstileKey(Date.now());  
    setResetFlip(true);
    setFlipCard(false);
    setAmount("");
    setUnit("");
    setStatus_code_Error("");
    setisclicked(true);
    setLoading(true);
    setCaptchaTrigger((prev) => !prev); 

    const cardNumberError = validateCardNumber(cardNumberValue);
    const pinCodeError = validatePinCode(pinCodeValue);
    if (cardNumberError || pinCodeError) {
      setShowcardNumberError(true);
      setShowpinCodeError(true);
      setCardNumberError(cardNumberError);
      setPinCodeError(pinCodeError);
      setLoading(false);
      return;
    }
    setShouldSubmit(true);
  };

  useEffect(() => {
    if (shouldSubmit && turnstileToken) {
      submitForm(turnstileToken);
      setShouldSubmit(false);  
    }
  }, [shouldSubmit, turnstileToken]);

  useEffect(() => {
    setCardNumberError(validateCardNumber(cardNumberValue));
    setPinCodeError(validatePinCode(pinCodeValue));
  }, [i18n.language, cardNumberValue, pinCodeValue]);

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCardNumberValue(value);
    setCardPreviewNumber(value.replace(/\D/g, "").slice(0, 16));
    setCardNumberError(validateCardNumber(value));
    setFlipCard(true);
  };

  const handlePinCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPinCodeValue(value);
    setCardPreviewPin(value.replace(/\D/g, "").slice(0, 4));
    setPinCodeError(validatePinCode(value));
    setFlipCard(true);
    if (!startTimePin) setStartTimePin(Date.now());
  };

  const handleCardNumberBlur = () => {
    setFlipCard(false);
    setResetFlip(true);
    setTimeout(() => setResetFlip(false), 600);
  };

  const handlePinCodeBlur = () => {
    setFlipCard(false);
    setResetFlip(true);
    setTimeout(() => setResetFlip(false), 600);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      style={styles.formContainer}
    >
      <TitleDescriptionSection title={title} description={description} />
      {showCard && (
        <CardPreview
          cardNumber={cardPreviewNumber}
          pin={cardPreviewPin}
          FlipCard={FlipCard}
          resetFlip={resetFlip}
        />
      )}
      <GiftCardForm
        cardNumberValue={cardNumberValue}
        setCardNumberValue={setCardNumberValue}
        pinCodeValue={pinCodeValue}
        setPinCodeValue={setPinCodeValue}
        cardNumberError={cardNumberError}
        setCardNumberError={setCardNumberError}
        pinCodeError={pinCodeError}
        setPinCodeError={setPinCodeError}
        showcardNumberError={showcardNumberError}
        showpinCodeError={showpinCodeError}
        handleCardNumberChange={handleCardNumberChange}
        handlePinCodeChange={handlePinCodeChange}
        handleCardNumberBlur={handleCardNumberBlur}
        handlePinCodeBlur={handlePinCodeBlur}
      />
      <Captcha
        siteKey="0x4AAAAAAAiLuudRjYNMO09Y"
        onSuccess={handleTurnstileSuccess}
        onError={handleTurnstileError}
        trigger={captchaTrigger}
        key={turnstileKey}
      />
      <Box style={styles.buttonContainer} marginBottom={10}>
        <ButtonBase
          type="submit"
          variant="contained"
          color="primary"
          disableRipple={true}
          disabled={loading}
          style={styles.submitBtn}
        >
          {shouldSubmit && !turnstileToken ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("submit")
          )}
        </ButtonBase>
      </Box>
      <Box marginTop={5}>
        {amount ? (
          <Typography variant="h6" style={styles.amount}>
            {t("amount", { amount, unit })}
          </Typography>
        ) : status_code_Error ? (
          <Typography variant="h6" style={styles.amount}>
            {t(status_code_Error)}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default CardGiftInput;
