import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";

const TitleDescriptionSection = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        id="titre"
        sx={{
          fontSize: {
            xs: '22px', 
            sm: '28px',  
            md: '36px'    
          },
          marginBottom: '4%',
          fontFamily: "Speedee-Bold",
        }}
      >
        {title || t("title")}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: '14px',  
            sm: '15px',  
            md: '16px'   
          },
          fontFamily: "Speedee",
        }}
      >
        {description || t("description")}
      </Typography>
    </Box>
  );
};

export default TitleDescriptionSection;
