import React, { useState, useEffect } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { Box } from "@mui/system";
import ReactDOM from "react-dom";

const Captcha = ({ turnstileKey, onSuccess, onError }) => {
  const [localTurnstileKey, setLocalTurnstileKey] = useState(turnstileKey);
  const siteKey = "0x4AAAAAAAiLuudRjYNMO09Y";

  const handleError = () => {
    console.error("Turnstile failed");
    if (onError) {
      onError();  
    }
     setLocalTurnstileKey(Date.now());
  };

  useEffect(() => {
    setLocalTurnstileKey(turnstileKey);
  }, [turnstileKey]);

  return (
    <>
      {ReactDOM.createPortal(
        <Box>
          <Turnstile
            siteKey={siteKey}
            onSuccess={onSuccess}
            onError={handleError}
            key={localTurnstileKey}
          />
        </Box>,
        document.body
      )}
    </>
  );
};

export default Captcha;
