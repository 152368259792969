import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CardGiftInput from "./components/giftCard/CardGiftInput";

class GiftCardComponent extends HTMLElement {
  static observedAttributes = ['title', 'description', 'show-card' ]; 

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  render() {
    const shadowRoot = this.shadowRoot;

    if (shadowRoot) {
      const shadowRootElement = document.createElement("div");
      shadowRoot.innerHTML = "";  
      shadowRoot.appendChild(shadowRootElement);

      const cache = createCache({
        key: "css",
        prepend: true,
        container: shadowRoot,
      });

      const shadowTheme = createTheme({
        components: {
          MuiPopover: {
            defaultProps: {
              container: shadowRootElement,
            },
          },
          MuiPopper: {
            defaultProps: {
              container: shadowRootElement,
            },
          },
          MuiModal: {
            defaultProps: {
              container: shadowRootElement,
            },
          },
        },
      });
      const title = this.getAttribute('title');
      const description = this.getAttribute('description');
      const showCard = this.getAttribute('show-card')  ? this.getAttribute('show-card') === 'true' : true;  
 
      ReactDOM.createRoot(shadowRootElement).render(
        <React.StrictMode>
          <CacheProvider value={cache}>
            <ThemeProvider theme={shadowTheme}>
              <CardGiftInput
                title={title}
                description={description}
                showCard={showCard}
               />
            </ThemeProvider>
          </CacheProvider>
        </React.StrictMode>
      );
    } else {
      console.error('Failed to access shadowRoot.');
    }
  }
}

customElements.define("gift-card-component", GiftCardComponent);
